import { createAuthenticaionService, makeQueryParams } from '@/services'

const BASE_URL = `${process.env.VUE_APP_API_URL}departments/`
class DepartmentService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  create(data) {
    return createAuthenticaionService(BASE_URL).post('/', data)
  }

  update(id, data) {
    return createAuthenticaionService(BASE_URL).put(`/${id}`, data)
  }

  deleteLogical(id, data) {
    return createAuthenticaionService(`${BASE_URL}delete/`).put(`/${id}`, data)
  }

  delete(id) {
    return createAuthenticaionService(BASE_URL).delete(`/${id}`)
  }

  deleteAll() {
    return createAuthenticaionService(BASE_URL).delete('/')
  }

  removeList(data) {
    return createAuthenticaionService(`${BASE_URL}removelist/`).put('/', data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  upload(data) {
    return createAuthenticaionService(`${BASE_URL}upload/`).post('/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  download(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}download/`).get(`/?${param}`)
  }
}

export default new DepartmentService()
